<template>
  <BeeHeader active="title" />
  <div class="bee-content-block bee-breadcroumb inner-bg-1">
    <div class="container">
      <div class="row">
        <div class="col-12 col-lg-7">
          <h1>{{ title }}</h1>
          <router-link :to="{ name: 'home' }">Home</router-link> / {{ title }}
        </div>
        <div class="col-12 col-lg-5 text-right">
          <h4>
            The world best quality of product and Training, for <span>Beekeeping</span> with
            <span>Bee!</span>
          </h4>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BeeHeader from "@/components/Header.vue";

export default {
  name: "Breadcrumbs",
  props: ["title"],
  components: {
    BeeHeader,
  },
};
</script>

<style></style>
