<template>
  <header class="for-sticky">
    <!-- Header top area start -->
    <div class="header-top-area">
      <div class="container">
        <div class="row">
          <div class="col-12 col-md-7 col-lg-6">
            <div class="top-contact">
              <a href="#"><i class="fa fa-envelope"></i> info@worldbeekeeping.com</a>
              <a href="#"><i class="fa fa-phone"></i> +88 01911 837404</a>
            </div>
          </div>
          <div class="col-12 col-md-5 col-lg-6 text-right">
            <div class="top-menu top-bar-menu">
              <ul>
                <li v-if="this.$store.state.userLoggedIn">
                  <router-link :to="{ name: 'admin' }">My Account</router-link>
                </li>
                <li v-if="this.$store.state.userLoggedIn">
                  <router-link :to="{ name: 'checkout' }">Checkout</router-link>
                </li>
                <li v-if="!this.$store.state.userLoggedIn">
                  <router-link :to="{ name: 'login' }">Login</router-link>
                </li>
                <li v-if="this.$store.state.userLoggedIn" @click="signout">
                  <router-link to="">Logout</router-link>
                </li>
              </ul>
            </div>
            <div v-if="this.$store.state.userLoggedIn" class="top-bar-cart" id="cart">
              <i class="fa fa-shopping-cart"></i><span class="badge">3</span>
            </div>
            <div class="shopping-cart">
              <div class="shopping-cart-header">
                <div class="cart-status">
                  <i class="fa fa-shopping-cart"></i><span class="badge">3</span>
                </div>
                <div class="shopping-cart-total">
                  <span class="lighter-text">Total:</span>
                  <span class="main-color-text">$2,229.97</span>
                </div>
              </div>
              <!--end shopping-cart-header -->

              <ul class="shopping-cart-items">
                <li v-for="(item, index) in this.$store.state.cart" :key="index" class="clearfix">
                  <img :src="item.productImage" alt="item1" />
                  <span class="item-name">{{ item.productName }}</span>
                  <span class="item-price">$ {{ item.productPrice }}</span>
                  <span class="item-quantity">Quantity: {{ item.productQuantity }}</span>
                </li>
              </ul>
              <router-link class="button" :to="{ name: 'checkout' }">Checkout</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Header top area End -->
    <!-- Header area start -->
    <div class="header-area">
      <div class="container">
        <!-- Site logo Start -->
        <div class="site-logo">
          <a href="/" title="w-Beekeeping">
            <img src="../assets/images/logo.png" alt="w-Beekeeping"
          /></a>
        </div>
        <!-- Site logo end -->
        <div class="mobile-menu-wrapper"></div>
        <!-- Search Start -->
        <div class="dropdown header-search-drop">
          <form action="#">
            <a
              class="dropdown-toggle"
              id="dropSearch"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i class="fa fa-search" aria-hidden="true"></i
            ></a>
            <input
              type="search"
              placeholder="kyewords.."
              class="dropdown-menu search-box"
              aria-labelledby="dropSearch"
            />
          </form>
        </div>
        <!-- Search End -->

        <!-- Main menu start -->
        <nav class="mainmenu">
          <ul id="navigation">
            <li
              v-for="(item, i) in items"
              :key="i"
              @click="current = i"
              :class="{ active: i == current }"
            >
              <router-link :to="item.name">{{ item.desc }}</router-link>
            </li>
          </ul>
        </nav>
        <!-- Main menu end -->
      </div>
    </div>
    <!-- Header area End -->
  </header>
  <!-- Main menu start -->
</template>

<script>
export default {
  name: "Header",
  props: ["title"],

  data() {
    return {
      current: null,
      items: [
        {
          name: "/",
          desc: "Home",
        },
        {
          name: "/about",
          desc: "About Us",
        },
        {
          name: "/blog",
          desc: "Blog",
        },
        {
          name: "/shop",
          desc: "Shop",
        },
        {
          name: "/news",
          desc: "News & Media",
        },
        {
          name: "/video",
          desc: "Videos",
        },
        {
          name: "/photo",
          desc: "Photos",
        },
        {
          name: "/contact",
          desc: "Contact Us",
        },
      ],
      cartModal: false,
    };
  },
  methods: {
    showCartModal() {
      this.cartModal = true;
    },
    signout() {
      this.$store.dispatch("signout", {
        router: this.$router,
        route: this.$route,
      });

      // console.log(this.$route);
      if (this.$route.meta.requiresAuth) {
        this.$router.push({ name: "home" });
      }
    },
  },
  mounted() {
    // eslint-disable-next-line no-undef
    $("#cart").on("click", () => {
      // eslint-disable-next-line no-undef
      $(".shopping-cart").fadeToggle("fast");
    });

    /* For mobile menu */
    // eslint-disable-next-line no-undef
    $("ul#navigation").slicknav({
      prependTo: ".mobile-menu-wrapper",
    });
    // eslint-disable-next-line no-undef
    $(".top-menu > ul").slicknav({
      prependTo: ".top-menu-holder",
      label: "Top Menu",
    });
  },
};
</script>

<style>
.mainmenu ul li.active > a {
  background-color: #ffcd00;
  text-decoration: none;
  color: #41140f;
}
.mainmenu ul li.nav-active > ul > li.child-active > a {
  background-color: #ffcd00;
  color: #000;
}
.mainmenu ul li.nav-active > ul > li > ul > li.child-active > a {
  background-color: #ffcd00;
}

.full-width-menu .nav-block ul li.active > a {
  background-color: #fff;
  text-decoration: none;
  color: #444444;
}
.full-width-menu .nav-block ul li.active > ul > li.child-active > a {
  background-color: #ffcd00;
  color: #444444;
}
.full-width-menu .nav-block ul li.active > ul > li > ul > li.child-active a {
  background-color: #ffcd00;
  color: #444444;
}
.full-width-menu .nav-block ul li:last-child ul li > ul,
.full-width-menu .nav-block ul li:nth-last-child(2) ul li > ul {
  left: -100%;
}
</style>
