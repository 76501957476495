<template>
  <Breadcrumbs title="Error 404" />
  <!-- Breadcroumbs end -->

  <div class="bee-content-block" id="maincontent">
    <div class="container">
      <div class="row">
        <div class="col-12 col-lg-10 offset-lg-1">
          <div class="error-section">
            <img src="../assets/images/404.png" alt="" />
            <h1>Page not found</h1>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Praesentium nesciunt
              perspiciatis laborum aliquid cupiditate distinctio minus quae officia repellendus
              maxime. Quia deserunt nostrum consequuntur veniam, quae quisquam saepe vel. Quod.
            </p>

            <router-link class="bee-button cta-btn" :to="{ name: 'home' }"
              >Back to home</router-link
            >
          </div>
        </div>
      </div>
    </div>
  </div>

  <Newsletter />
</template>

<script>
// @ is an alias to /src
// import BeeSlider from '@/components/Slider.vue';

import Breadcrumbs from "@/components/Breadcrumbs.vue";
import Newsletter from "@/components/Newsletter.vue";

export default {
  name: "About",
  components: {
    Breadcrumbs,
    Newsletter,
  },
};
</script>
