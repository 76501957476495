<template>
  <div class="bee-content-block newsletter-signup">
    <div class="container">
      <form action="#">
        <div class="row">
          <div class="col-12 col-sm-6 col-md-3 col-lg-3">
            <input class="form-control" type="text" placeholder="First Name" />
          </div>
          <div class="col-12 col-sm-6 col-md-3 col-lg-3">
            <input class="form-control" type="text" placeholder="Last Name" />
          </div>
          <div class="col-12 col-sm-6 col-md-3 col-lg-3">
            <input class="form-control" type="text" placeholder="Email" />
          </div>
          <div class="col-12 col-sm-6 col-md-3 col-lg-3">
            <input class="btn btn-bee-news" type="submit" value="Signup For Newsletter" />
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: "Newsletter",
};
</script>

<style></style>
